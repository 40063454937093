<template>
    <div class="app">
        <Header />
        <Services />
        <Stats />
        <Ourwork />
        <Prices />
        <Testimonal />
        <Contact />
    </div>
</template>

<script>
import Prices from "@/components/Home/Prices.vue"
import Ourwork from "@/components/Home/Ourwork.vue";
import Stats from "@/components/Home/Stats.vue";
import Testimonal from "@/components/Home/Testimonal.vue";
import Services from "../components/Home/ourservices.vue"
import Header from "../components/Home/Header.vue"
import Contact from "../components/Home/ContactTab.vue"
export default {
    name: "HomeView",
    components: {  Header, Testimonal, Ourwork, Stats, Prices, Services, Contact }
}
</script>
<style lang="scss">
</style>
