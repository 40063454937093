<template>
    <div class="app">
        <HeaderAbout />
        <TeamVue />
        <Aboutus />
        <AboutendVue />
    </div>
</template>
<script>
import HeaderAbout from '@/components/About/Header-About.vue';
import Aboutus from '@/components/About/Aboutus.vue';
import TeamVue from '@/components/About/Team.vue'
import AboutendVue from '@/components/About/Aboutend.vue';
export default {
    name: "AboutView",
    components: { HeaderAbout, Aboutus, AboutendVue, TeamVue }
}
</script>