<template>
    <div class="app">
        <contactVue />
        <Contactus />
        <LocationView />
    </div>
</template>
<script>
import contactVue from '@/components/Contact/contact.vue';
import Contactus from "@/components/Contact/Contactus.vue";
import LocationView from "@/components/Contact/map.vue"
export default {
    name: "ContactView",
    components: { contactVue, Contactus, LocationView }
}
</script>