<template>
    <div class="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
      <div class="inset-y-0 top-0 right-0 z-0 w-full max-w-xl mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
        <svg class="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block" viewBox="0 0 100 100" fill="currentColor" preserveAspectRatio="none slice">
          <path d="M50 0H100L50 100H0L50 0Z"></path>
        </svg>
        <img
          class="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
          alt=""
        />
      </div>
      <div class="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div class="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
          <h2 class="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            ABOUT US<br class="hidden md:block" />
          </h2>
          <p class="pr-5 mb-5 text-base text-gray-700 md:text-lg">
            Every detail of ours is designed to provide the same level of care and every work of ours is done in order to create a positive impact. Our sectors are identified by the standard we set for everything we do. For this reason, attention to detail is never lacking, it just changes shape. We are committed to providing every customer with the highest standard of customer service. Our vision is to make all our customers satisfied. We hope you have a pleasant experience with us!
          </p>
          <div class="flex items-center">
            <router-link :to="{ name: 'Contact' }" class="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none" aria-label="Contact Us" >Get started</router-link>
            <a href="#more" aria-label="" class="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700">Learn more</a>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    name:"Header-About",
}
</script>
<style lang="scss" scoped>
a{
  text-decoration: none;
}
</style>