<template>
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>About</h6>
            <p class="text-justify" style="color: rgba(255, 255, 255, 0.797);">Intrioxa.com | WE CAN AND WE WILL! | Our vision is to make all our customers satisfied. We hope you have a pleasant experience with us ! <br> 
 Mission - We deliver uniqueness and quality. <br>
 Skills - Delivering fast and excellent results. <br>
 Clients - Satisfied clients thanks to our experience.</p>
          </div>

          <div class="col-xs-6 col-md-3">
            <h6>News</h6>
            <ul class="footer-links">
              <li><a href="https://intrioxa-blogs.web.app/">Getting Started with Digital Design</a></li>
              <li><a href="https://intrioxa-blogs.web.app/">Best Cracking Tools</a></li>
              <li><a href="https://intrioxa-blogs.web.app/">What Happens When We Surf The Internet</a></li>
              <li><a href="https://intrioxa-blogs.web.app/">Why Cloud Services Are Not Safe For You</a></li>
              <li><a href="https://intrioxa-blogs.web.app/">Different Types Of Security Attacks Which Are Used For Cracking Password</a></li>
            </ul>
          </div>

          <div class="col-xs-6 col-md-3">
            <h6>Quick Links</h6>
            <ul class="footer-links">
              <li><router-link :to="{ name: 'About' }">About</router-link></li> 
              <li><router-link :to="{ name: 'Contact' }">Contact</router-link></li>
              <li><router-link :to="{ name: 'Privacy Policy' }">Privacy Policy</router-link></li>
              <li><router-link :to="{ name: 'Terms & Conditions' }">Terms & Conditions</router-link></li>
              <li><router-link :to="{ name: 'Subscribe' }">Subscribe</router-link></li>
              <li><a href="https://www.buymeacoffee.com/intrioxa">Contribute</a></li>
            </ul>
          </div>
        </div>
        <hr> 
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text" style="color: rgba(255, 255, 255, 0.797);">Copyright &copy; {{currentYear}} All Rights Reserved by 
         <a href="https://www.intrioxa.com/">Intrioxa</a>.
            </p>
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12">
            <ul class="social-icons">
              <a href="https://www.facebook.com/Intrioxa" class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400">
                <img src="https://companieslogo.com/img/orig/META.D-61ba95b4.png?t=1654568366" class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400" alt="Meta Logo">
              </a>
              <a href="https://www.tiktok.com/@intrioxa" class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400">
                <img src="@/assets/tiktok-logo.png" class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400" alt="TikTok Logo">
              </a>
            <a href="https://www.instagram.com/intrioxa/" class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400">
              <svg viewBox="0 0 30 30" fill="currentColor" class="svg-icon">
                <circle cx="15" cy="15" r="4"></circle>
                <path
                  d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
                ></path>
              </svg>
            </a> 
            </ul>
          </div>
          
        </div>
      </div>
</footer>
</template>
<script>
export default {
  name: "Footer",
  components: {
  },
  data() {
    return {
      currentYear: new Date().getFullYear(), 
    };
  }
};
</script>
<style lang="scss" scoped>
.site-footer
{
  background-color: #303030;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:rgba(255, 255, 255, 0.797);
}
.site-footer hr
{
  padding: 10px;
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.text-justify p{
  color: rgba(255, 255, 255, 0.797);
}
.site-footer a
{
    color:rgba(255, 255, 255, 0.797);
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
    color:rgba(255, 255, 255, 0.797);
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}

.site-footer .social-icons a 
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons .svg-icon{
  fill: #eee;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
}
.social-icons img{
  display: inline-block;
  width:44px;
  height:100%;
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}

.svg-icon {
  background-color:#393939;
  color:#333333;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
  }
@media (max-width:767px)
{
  .social-icons 
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
</style>