<template>
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
        </div>
        <h2 class="max-w-lg mb-10 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <h1 class="t" id="services">Our Services</h1>
        </h2>
      </div>
      <div class="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
        <div class="p-8 border-b sm:border-r">
          <div class="max-w-md text-center">
            <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg class="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12" stroke="currentColor" viewBox="0 0 52 52">
                <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
              </svg>
            </div>
            <h6 class="mb-2 font-semibold leading-5">WEB DESIGNS</h6>
            <p class="mb-3 text-sm text-gray-900">
                Your website design shows your business insight. A well known saying is “First impression is the lasting one”. In web technologies, your website is the first entity that interacts with the visitor, so your website should speak itself!
            </p>
          </div>
        </div>
        <div class="p-8 border-b lg:border-r">
          <div class="max-w-md text-center">
            <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg class="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12" stroke="currentColor" viewBox="0 0 52 52">
                <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
              </svg>
            </div>
            <h6 class="mb-2 font-semibold leading-5">WEB DEVELOPMENT</h6>
            <p class="mb-3 text-sm text-gray-900">
                Our approach is to go back to basics and work out what our clients – and their users – actually need to do. We build these from the ground up, using industry standard development frameworks so it’s perfectly tailored to your needs and sustainable in the long term.
            </p>
          </div>
        </div>
        <div class="p-8 border-b sm:border-r lg:border-r-0">
          <div class="max-w-md text-center">
            <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg class="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12" stroke="currentColor" viewBox="0 0 52 52">
                <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
              </svg>
            </div>
            <h6 class="mb-2 font-semibold leading-5">MOBILE SOLUTIONS</h6>
            <p class="mb-3 text-sm text-gray-900">
                We make the User Experience more suitable for your app. UX is the content management discipline: we study the path of users within your app, create tailor-made texts, and split them up in the best way.
            </p>
          </div>
        </div>
        <div class="p-8 border-b lg:border-b-0 lg:border-r">
          <div class="max-w-md text-center">
            <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg class="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12" stroke="currentColor" viewBox="0 0 52 52">
                <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
              </svg>
            </div>
            <h6 class="mb-2 font-semibold leading-5">IT SOLUTIONS</h6>
            <p class="mb-3 text-sm text-gray-900">
                Intrioxa staff has many years of experience in solving various problems related to information technology. You get the experience and expertise at a fraction of the cost of hiring and training your team.
            </p>
          </div>
        </div>
        <div class="p-8 border-b sm:border-b-0 sm:border-r">
          <div class="max-w-md text-center">
            <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg class="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12" stroke="currentColor" viewBox="0 0 52 52">
                <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
              </svg>
            </div>
            <h6 class="mb-2 font-semibold leading-5">A+ HOSTING</h6>
            <p class="mb-3 text-sm text-gray-900">
                However, Intrioxa digital marketing or web design services don’t end when a project is complete. We will host your website redesign, brand new design or online marketing campaign to ensure you have the best care and attention.
            </p>
          </div>
        </div>
        <div class="p-8">
          <div class="max-w-md text-center">
            <div class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              <svg class="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12" stroke="currentColor" viewBox="0 0 52 52">
                <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
              </svg>
            </div>
            <h6 class="mb-2 font-semibold leading-5">MARKET STRATEGY</h6>
            <p class="mb-3 text-sm text-gray-900">
                Intrioxa is a leading digital marketing agency, committed to improving your brand. We take a non-traditional approach to traditional media – breaking standards, methods and outdated practices of audience engagement.
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
export default {
  name: "Services"
}
</script>