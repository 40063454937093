<template>
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div class="mx-auto mb-10 lg:max-w-xl sm:text-center text-center">
        <p class="inline-block px-3 py-px mb-4 text-ms font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-gray-accent-50">
          Discover Our Team
        </p>
        <p class="text-base text-gray-700 md:text-lg">
            Get to know the skilled professionals behind Intrioxa. Our team works together to deliver exceptional results for our clients.
        </p>
      </div>
      <div class="grid gap-10 mx-auto sm:grid-cols-2  lg:max-w-screen-lg">
        <div>
          <div class="relative pb-56 mb-4 rounded shadow lg:pb-64">
            <img class="absolute object-cover w-full h-full rounded profile-img" src="@/assets/team-1.webp" alt="Florent Gashi" />
          </div>
          <div class="flex flex-col sm:text-center text-center">
            <p class="text-lg font-bold">Florent Gashi</p>
            <p class="text-xs text-gray-800">CEO / Founder</p>
            <div class="flex items-center space-x-3 sm:justify-center social-profile">
              <a href="https://www.linkedin.com/in/florent-gashi-401b5424b/" class="text-gray-600 m-auto transition-colors duration-300 hover:text-deep-purple-accent-400">
                <img class="w-6 social-profile" src="https://digi-act.com/wp-content/uploads/2019/12/linkedin-icon-logo-png-transparent.png" alt="Linkedin Logo">
              </a>
              <a href="https://www.instagram.com/florenttgashi/" class="text-gray-600 transition-colors m-auto duration-300 hover:text-deep-purple-accent-400">
                <img class="w-9 social-profile"  src="https://cdn-icons-png.flaticon.com/512/3621/3621435.png" alt="Instagram Logo">
              </a>
            </div>
          </div>
        </div>
        <div>
          <div class="relative pb-56 mb-4 rounded shadow lg:pb-64">
            <img class="absolute object-cover w-full h-full rounded profile-img" src="@/assets/team-2.webp" alt="Erjon Kryeziu" />
          </div>
          <div class="flex flex-col sm:text-center text-center align-center text">
            <p class="text-lg font-bold">Erjon Kryeziu</p>
            <p class="text-xs text-gray-800">Front-End Team Lead</p>
            <div class="flex items-center space-x-3 sm:justify-center social-profile">
              <a href="https://www.linkedin.com/in/erjon-kryeziu-891ab324a/" class="text-gray-600 m-auto transition-colors duration-300 hover:text-deep-purple-accent-400">
                <img class="w-6 social-profile" src="https://digi-act.com/wp-content/uploads/2019/12/linkedin-icon-logo-png-transparent.png" alt="Linkedin Logo">
              </a>
              <a href="https://www.instagram.com/erjonikr/" class="text-gray-600 transition-colors m-auto duration-300 hover:text-deep-purple-accent-400">
                <img class="w-9 social-profile"  src="https://cdn-icons-png.flaticon.com/512/3621/3621435.png" alt="Instagram Logo">
              </a>
            </div>
          </div>
        </div>
        <div>
          <div class="relative pb-56 mb-4 rounded shadow lg:pb-64">
            <img class="absolute object-cover w-full h-full rounded profile-img" src="@/assets/team-4.webp" alt="Erion Morina" />
          </div>
          <div class="flex flex-col sm:text-center text-center align-center text">
            <p class="text-lg font-bold">Erion Morina</p>
            <p class="text-xs text-gray-800">Front-End Developer</p>
            <div class="flex items-center space-x-3 sm:justify-center social-profile">
              <a href="https://www.linkedin.com/in/erion-morina-242376259/" class="text-gray-600 m-auto transition-colors duration-300 hover:text-deep-purple-accent-400">
                <img class="w-6 social-profile" src="https://digi-act.com/wp-content/uploads/2019/12/linkedin-icon-logo-png-transparent.png" alt="Linkedin Logo">
              </a>
              <a href="https://www.instagram.com/0eriion/" class="text-gray-600 transition-colors m-auto duration-300 hover:text-deep-purple-accent-400">
                <img class="w-9 social-profile"  src="https://cdn-icons-png.flaticon.com/512/3621/3621435.png" alt="Instagram Logo">
              </a>
            </div>
          </div>
        </div>
        <div>
          <div class="relative pb-56 mb-4 rounded shadow lg:pb-64">
            <img class="absolute object-cover w-full h-full rounded profile-img" src="@/assets/team-5.webp" alt="Erion Morina" />
          </div>
          <div class="flex flex-col sm:text-center text-center align-center text">
            <p class="text-lg font-bold">Ermal Gashi</p>
            <p class="text-xs text-gray-800">Front-End Developer</p>
            <div class="flex items-center space-x-3 sm:justify-center social-profile">
              <a href="https://ermal.intrioxa.com/" class="text-gray-600 m-auto transition-colors duration-300 hover:text-deep-purple-accent-400">
                <img class="w-6 social-profile" src="https://www.freepnglogos.com/uploads/logo-website-png/logo-website-world-wide-web-the-internet-know-your-meme-7.png" alt="Website Link">
              </a>
              <a href="https://www.instagram.com/ermal.gaa/" class="text-gray-600 transition-colors m-auto duration-300 hover:text-deep-purple-accent-400">
                <img class="w-9 social-profile"  src="https://cdn-icons-png.flaticon.com/512/3621/3621435.png" alt="Instagram Logo">
              </a>
            </div>
          </div>
        </div>
        <div>
          <div class="relative pb-56 mb-4 rounded shadow lg:pb-64">
            <img class="absolute object-cover w-full h-full rounded profile-img" src="@/assets/team-3.webp" alt="Eduart Gashi" />
          </div>
          <div class="flex flex-col sm:text-center text-center align-center text">
            <p class="text-lg font-bold">Eduart Gashi</p>
            <p class="text-xs text-gray-800">Graphic Designer</p>
            <div class="flex items-center space-x-3 sm:justify-center social-profile">
              <a href="https://www.instagram.com/breezy.eg/" class="text-gray-600 transition-colors m-auto duration-300 hover:text-deep-purple-accent-400">
                <img class="w-9 social-profile"  src="https://cdn-icons-png.flaticon.com/512/3621/3621435.png" alt="Instagram Logo">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    name: "TeamVue",
}
</script>
<style lang="scss" scoped>
.social-profile{
  align-self: center !important;
  filter: grayscale(100%);
}
.social-profile:hover{
  filter: none;
}
</style>