<template>
  <div class="flex flex-col items-center justify-center px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:pt-32 md:px-0">
    <div class="flex flex-col items-center max-w-2xl md:px-8">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span class="relative inline-block">
            <svg viewBox="0 0 52 24" fill="currentColor" class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
              <defs>
                <pattern id="192913ce-1f29-4abd-b545-0fbccfd2b0ec" x="0" y="0" width=".135" height=".30">
                  <circle cx="1" cy="1" r=".7"></circle>
                </pattern>
              </defs>
              <rect fill="url(#192913ce-1f29-4abd-b545-0fbccfd2b0ec)" width="42" height="29"></rect>
            </svg>
            <span class="relative"></span>
          </span>
          Subscribe To Our Newsletter
        </h2>
        <p class="text-base text-gray-700 md:text-lg">
          Signup to our email newsletter for limited edition offers, and more special surprises!
        </p>
      </div>
      <form class="flex flex-col items-center w-full mb-4 md:flex-row" action="https://formsubmit.co/info@intrioxa.com" method="POST">
        <input type="text" name="_honey" style="display: none;">
              <input type="hidden" name="_autoresponse" value="We've received your email. 
              We do our best to respond to every email within 24 hours. 
              If we take a little longer, that means we're experiencing high volumes. 
              Best regards,Intrioxa.">
              <input type="hidden" name="_next" value="https://www.intrioxa.com/thank-you">
              <input type="hidden" name="_subject" value="Intrioxa Mail!">
        <input
          placeholder="Name"
          required=""
          name="name"
          type="text"
          class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
        />
        <input
          placeholder="Email"
          required=""
          name="email"
          type="text"
          class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
        />
        <button
          type="submit"
          class="inline-flex items-center justify-center mb-3 w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
        >
          Subscribe
        </button>
      </form>
      <p class="max-w-md mb-10 text-xs text-gray-600 sm:text-sm md:text-center">
      </p>
    </div>
    <img src="https://kitwind.io/assets/kometa/half-browser.png" class="w-full max-w-screen-sm mx-auto rounded shadow-2xl md:w-auto lg:max-w-screen-md" alt="" />
  </div>
</template>
<script>
export default {
    name: "Subscribe"
}
</script>