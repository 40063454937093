<template>
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div class="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div class="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img src="https://images.pexels.com/photos/2408666/pexels-photo-2408666.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500" class="object-cover w-full h-64" alt="" />
          <div class="p-5 border border-t-0">
            <p class="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a href="/" class="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700" aria-label="Category" title="web-development">Web Development</a>
              <span class="text-gray-600">— 21 Jun 2023</span>
            </p>
            <a href="/" aria-label="Category" title="Mastering the Art of Web Development: Tips and Tricks for Beginners" class="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700">Mastering the Art of Web Development: Tips and Tricks for Beginners</a>
            <p class="mb-2 text-gray-700">
                Are you eager to dive into the world of web development but feeling overwhelmed by the vast amount of information and technical jargon? Look no further, as we have the ultimate guide to help beginners like you master the art of web development. 
            </p>
            <a href="Post" aria-label="" class="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">Learn more</a>
          </div>
        </div>
        <!-- <div class="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img src="https://images.pexels.com/photos/447592/pexels-photo-447592.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260" class="object-cover w-full h-64" alt="" />
          <div class="p-5 border border-t-0">
            <p class="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a href="/" class="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700" aria-label="Category" title="traveling">traveling</a>
              <span class="text-gray-600">— 28 Dec 2020</span>
            </p>
            <a href="/" aria-label="Category" title="Simple is better" class="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700">Simple is better</a>
            <p class="mb-2 text-gray-700">
              Sed ut perspiciatis unde omnis iste natus error sit sed quia consequuntur magni voluptatem doloremque.
            </p>
            <a href="/" aria-label="" class="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">Learn more</a>
          </div>
        </div> -->
        <!-- <div class="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
          <img src="https://images.pexels.com/photos/139829/pexels-photo-139829.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260" class="object-cover w-full h-64" alt="" />
          <div class="p-5 border border-t-0">
            <p class="mb-3 text-xs font-semibold tracking-wide uppercase">
              <a href="/" class="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700" aria-label="Category" title="traveling">traveling</a>
              <span class="text-gray-600">— 28 Dec 2020</span>
            </p>
            <a href="/" aria-label="Category" title="Film It!" class="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700">Film It!</a>
            <p class="mb-2 text-gray-700">
              Sed ut perspiciatis unde omnis iste natus error sit sed quia consequuntur magni voluptatem doloremque.
            </p>
            <a href="/" aria-label="" class="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">Learn more</a>
          </div>
        </div> -->
      </div>
    </div>
  </template>

<script>
export default{
    name: "Blogs",
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none;
}
</style>