<template>
  <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" id="more">
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        <span class="relative inline-block">
          <svg viewBox="0 0 52 24" fill="currentColor" class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
            <defs>
              <pattern id="b902cd03-49cc-4166-a0ae-4ca1c31cedba" x="0" y="0" width=".135" height=".30">
                <circle cx="1" cy="1" r=".7"></circle>
              </pattern>
            </defs>
            <rect fill="url(#b902cd03-49cc-4166-a0ae-4ca1c31cedba)" width="52" height="24"></rect>
          </svg>
          <span class="relative">4</span>
        </span>
        Stages of the Web Development Process
      </h2>
      <p class="text-base text-gray-700 md:text-lg">
        So you've decided it's time for a new website. Now what?

The decision to upgrade your website is a big one. It requires time, money, and energy. So what should you expect during the process? We break down our process for you below!
      </p>
    </div>
    <div class="grid gap-10 lg:grid-cols-4 sm:grid-cols-2">
      <div>
        <div class="flex items-center justify-between mb-6">
          <p class="text-2xl font-bold">Step 1 - Design</p>
          <svg class="w-6 text-gray-700 transform rotate-90 sm:rotate-0" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <line fill="none" stroke-miterlimit="10" x1="2" y1="12" x2="22" y2="12"></line>
            <polyline fill="none" stroke-miterlimit="10" points="15,5 22,12 15,19 "></polyline>
          </svg>
        </div>
        <p class="text-gray-600">
          This is the fun part. You'll likely talk about color schemes, function, special features (like e-commerce), graphics, videos, and the overall look. We always recommend coming to the meeting with a few examples of websites you love. After that meeting, the Intrioxa team takes some time to bring your vision to life in 2D. 
        </p>
      </div>
      <div>
        <div class="flex items-center justify-between mb-6">
          <p class="text-2xl font-bold">Step 2 - Development</p>
          <svg class="w-6 text-gray-700 transform rotate-90 sm:rotate-0" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <line fill="none" stroke-miterlimit="10" x1="2" y1="12" x2="22" y2="12"></line>
            <polyline fill="none" stroke-miterlimit="10" points="15,5 22,12 15,19 "></polyline>
          </svg>
        </div>
        <p class="text-gray-600">
          Now that the design is approved, it is time for the website to go to the developer. The developer starts to build the website on a production server so you can view the site online without it being viewable to the public. During that time, you can work with the copywriter and other people in the agency to nail down the voice, content, and graphics. This is usually the longest part of the process, but it is the most important!
        </p>
      </div>
      <div>
        <div class="flex items-center justify-between mb-6">
          <p class="text-2xl font-bold">Step 3 - Testing</p>
          <svg class="w-6 text-gray-700 transform rotate-90 sm:rotate-0" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <line fill="none" stroke-miterlimit="10" x1="2" y1="12" x2="22" y2="12"></line>
            <polyline fill="none" stroke-miterlimit="10" points="15,5 22,12 15,19 "></polyline>
          </svg>
        </div>
        <p class="text-gray-600">
          Once your website has been coded by the developer and you've settled on all of your content, it is time to review your new site on the test server. You'll receive a link to view the website and share with your company and decision-makers. Usually there are a few tweaks that need to be made at this stage before the site is finalized.
        </p>
      </div>
      <div>
        <div class="flex items-center justify-between mb-6">
          <p class="text-2xl font-bold">Success - Launch!</p>
          <svg class="w-8 text-green-600" stroke="currentColor" viewBox="0 0 24 24">
            <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8"></polyline>
          </svg>
        </div>
        <p class="text-gray-600">
          At long last, your new site is ready to launch! Once you give the go-ahead, the developer will start the launch process. This includes setting up hosting and getting the domain pointed. It will also take some time to propagate. Be patient — it will be worth it! After a few hours, your site should be live and ready for you and the rest of the world to enjoy!
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default{
    name:"Aboutus",
}
</script>
<style lang="scss" scoped>
</style>