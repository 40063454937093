<template>
<section class="campus" id="Work">
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-10 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <h1 class="t">Our Work</h1>
        </h2>
      </div>
        <div class="row3">
            <div class="nameofwork">
                <h3>Intrioxa Mp3</h3>
            </div>
            <div class="campus-col">
               <a href="https://mp3.intrioxa.com/"> <img src="@/assets/mp3_intrioxa.webp" alt="Intrioxa Mp3">
                <div class="layer">
                    <h3>Intrioxa Mp3</h3>
                </div></a>
            </div>
            <div class="nameofwork">
                <h3>Intrioxa Code Editor</h3>
            </div>
            <div class="campus-col">
               <a href="https://editor.intrioxa.com/"> <img src="@/assets/intrioxa-editor.webp" alt="Intrioxa Code Editor">
                <div class="layer">
                    <h3>Intrioxa Code Editor</h3>
                </div></a>
            </div>
            <div class="nameofwork">
                <h3>Keka-Med</h3>
            </div>
            <div class="campus-col">
               <a href="https://www.kekamed.com/"> <img src="@/assets/keka.webp" alt="Keka Med">
                <div class="layer">
                    <h3>Keka-Med</h3>
                </div></a>
            </div>
            <div class="nameofwork">
                <h3>Bocaki Studio</h3>
            </div>
            <div class="campus-col">
               <a href="https://www.bocakistudio.com/"> <img src="@/assets/bocaki_studio.webp" alt="Bocaki Studio">
                <div class="layer">
                    <h3>Bocaki Studio</h3>
                </div></a>
            </div> 
            <div class="nameofwork">
                <h3>KV-Klina</h3>
            </div>
            <div class="campus-col">
               <a href="https://www.kv-klina.com/"> <img src="@/assets/KV-klina.webp" alt="KV-Klina">
                <div class="layer">
                    <h3>KV-Klina</h3>
                </div></a>
            </div> 
            <div class="nameofwork">
                <h3>EupHype</h3>
            </div>
            <div class="campus-col">
               <a href="https://www.euphype.com/"> <img src="@/assets/euphype.webp" alt="EupHype">
                <div class="layer">
                    <h3>EupHype</h3>
                </div></a>
            </div>
            <div class="nameofwork">
                <h3>KAAG Invest</h3>
            </div>
            <div class="campus-col">
               <a href="https://kaag-invest.com/"> <img src="@/assets/KAAG-Invest.webp" alt="Intrioxa Code Editor">
                <div class="layer">
                    <h3>KAAG Invest</h3>
                </div></a>
            </div>
            <div class="nameofwork">
                <h3>Intrioxa Weather</h3>
            </div>
            <div class="campus-col">
               <a href="https://weather.intrioxa.com"> <img src="@/assets/Weather-App.webp" alt="Intrioxa Weather">
                <div class="layer">
                    <h3>Intrioxa Weather</h3>
                </div></a>
            </div>
            <!-- <div class="nameofwork">
                <h3>Intrioxa Calculator</h3>
            </div>
            <div class="campus-col">
               <a href="https://calculator.intrioxa.com/"> <img src="@/assets/intrioxa-calculator.png" alt="Intrioxa Calculator">
                <div class="layer">
                    <h3>Intrioxa Calculator</h3>
                </div></a>
            </div> -->
        </div>
    </section>
</template>
<script>
export default{
    name:"Ourwork"
}
</script>
<style lang="scss">
.t{
    color: #3e2093;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.campus {
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 50px;
}
.row3{
  margin-top: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  justify-content: space-between;
}

.campus-col{
  flex-basis: 32%;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
} 
.campus-col img{ 
  width: 100%;
  display: block;
}
.layer{
  background: transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}
.layer:hover{
  background: rgba(245, 246, 247, 0.712);
}
.layer h3{
  width: 100%;
  font-weight: 500;
  color: rgb(65, 58, 58);
  font-size: 26px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  opacity: 0;
  transition: 0.5s;
}
.layer:hover h3{
  bottom: 35%;
  opacity: 1;
}
.nameofwork{
  display: none;
}

@media only screen and (max-width: 700px) {
.nameofwork{
  display: block;
}
.t{
    padding-bottom: 35px;
}
}
@media(max-width: 700px){
    .t{
        font-size: 32px;
    }
    .row3{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
}
</style>