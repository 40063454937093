<template>
  <div id="Pricing">
    <div class="relative w-full h-full">
      <div class="absolute hidden w-full bg-gray-50 lg:block h-96"></div>
      <div class="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 class="max-w-lg mb-10 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span class="relative inline-block">
              <svg viewBox="0 0 52 24" fill="currentColor" class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-400 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                <defs>
                  <pattern id="2c67e949-4a23-49f7-bf27-ca140852cf21" x="0" y="0" width=".135" height=".30">
                    <circle cx="1" cy="1" r=".7"></circle>
                  </pattern>
                </defs>
                <rect fill="url(#2c67e949-4a23-49f7-bf27-ca140852cf21)" width="52" height="24"></rect>
              </svg>
              <span class="relative"></span>
            </span>
            <h1 class="t">Affordable For Everyone</h1>
        </h2>
          <p class="text-base text-gray-700 md:text-lg">
            Start Building Your Online Presence with Intrioxa. Compare Plans & Pricing and Get Online Fast!
          </p>
        </div>
        <div class="grid max-w-screen-md gap-10 md:grid-cols-2 sm:mx-auto">
          <div>
            <div class="p-8 bg-gray-900 rounded">
              <div class="mb-4 text-center">
                <p class="text-xl font-medium tracking-wide text-white">
                  Elite Plan
                </p>
                <div class="flex items-center justify-center">
                  <p class="mr-2 text-5xl font-semibold text-white lg:text-6xl">
                    $150
                  </p>
                  <p class="text-lg text-gray-500">/ $350</p>
                </div>
              </div>
              <ul class="mb-8 space-y-2">
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">Single Website Page</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">10 GB of storage</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">Free Custom Domain Name</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">3 Custom Domain Email Accounts</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">Mobile Friendly</p>
                </li>
              </ul>
              <button
                type="submit"
                class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
              <a href="mailto:info@intrioxa.com?subject=Elite Plan!&body=To get started on building your new website, I'll need a few more pieces of information from you. %0D%0A Here are some questions that will help me understand your needs: %0D%0A %0D%0A 1) Your company name and a brief description of your business.
              %0D%0A 2) The primary purpose of your website and your target audience.
              %0D%0A 3) The type of content you want to include on your website (text, images, videos, etc.).
              %0D%0A 4) Your preferred color scheme, branding, and design elements.
              %0D%0A 5) Examples of websites that you like and would like your website to emulate. 
              %0D%0A6) Your budget and timeline for the project." style="text-decoration: none; color: #fff;">Contact Now</a>
              </button>
            </div>
            <div class="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75"></div>
            <div class="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50"></div>
            <div class="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25"></div>
          </div>
          <div>
            <div class="p-8 bg-gray-900 rounded">
              <div class="mb-4 text-center">
                <p class="text-xl font-medium tracking-wide text-white">
                  Pro Plan
                </p>
                <div class="flex items-center justify-center">
                  <p class="mr-2 text-5xl font-semibold text-white lg:text-6xl">
                    $500
                  </p>
                  <p class="text-lg text-gray-500">/ $1000</p>
                </div>
              </div>
              <ul class="mb-8 space-y-2">
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">Multi Website Page</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">100 GB of storage</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">Free Custom Domain Name</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">30 Custom Domain Email Accounts</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">Mobile Friendly</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">Remove Intrioxa.com Banner Ads</p>
                </li>
                <li class="flex items-center">
                  <div class="mr-3">
                    <svg class="w-4 h-4 text-teal-accent-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                      <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                      <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                    </svg>
                  </div>
                  <p class="font-medium text-gray-300 m-0">Premium Support</p>
                </li>
              </ul>
              <button
                type="submit"
                class="inline-flex items-center justify-center w-full h-12 px-6 font-semibold tracking-wide text-teal-900 transition duration-200 rounded shadow-md bg-teal-accent-400 hover:bg-teal-accent-700 focus:shadow-outline focus:outline-none"
              >
              <a href="mailto:info@intrioxa.com?subject=Pro Plan!&body=To get started on building your new website, I'll need a few more pieces of information from you. %0D%0A Here are some questions that will help me understand your needs: %0D%0A %0D%0A 1) Your company name and a brief description of your business.
              %0D%0A 2) The primary purpose of your website and your target audience.
              %0D%0A 3) The type of content you want to include on your website (text, images, videos, etc.).
              %0D%0A 4) Your preferred color scheme, branding, and design elements.
              %0D%0A 5) Examples of websites that you like and would like your website to emulate. 
              %0D%0A 6) The features and functionality you want to include on your website, such as: %0D%0A
              • E-commerce functionality (if you plan to sell products or services online), %0D%0A
              • A blog or news section, %0D%0A
              • Contact forms or other interactive elements, %0D%0A
              • Integration with social media platforms, %0D%0A
              • Analytics tracking to monitor website traffic.
              %0D%0A 7) Your budget and timeline for the project." style="text-decoration: none; color: #004D40;">Contact Now</a>
              </button>
            </div>
            <div class="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75"></div>
            <div class="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50"></div>
            <div class="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
 <script>
export default {
  name: "Prices"
}</script>
<style>

</style>