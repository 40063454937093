import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import Subscribe from '../components/Home/Subscribe.vue'
import Post from '../components/Blog/post.vue'
import BlogView from '../components/Blog/blogs.vue'
import PageNotFound from '../components/PageNotFound.vue'
import PrivacyPolicy from '../components/PrivacyPolicy.vue'
import TermsConditions from '@/components/Terms & Conditions.vue'
import ThankYou from '../components/Thankyou.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      title: "Home"
    },
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
    meta: {
      title: "About"
    },
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogView,
    meta: {
      title: "Blog"
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView,
    meta: {
      title: "Contact"
    },
  },
  {
    path: '/thank-you',
    name: 'Thank You',
    component: ThankYou,
    meta: {
      title: "Thank You"
    },
  },
  {
    path: '/post',
    name: 'Post',
    component: Post,
    meta: {
      title: "Post"
    },
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe,
    meta: {
      title: "Subscribe"
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy"
    },
  },
  {
    path: "/terms&conditions",
    name: "Terms & Conditions",
    component: TermsConditions,
    meta: {
      title: "Terms & Conditions"
    },
  },
  { path: "/404", meta: {title: "Page Not Found"}, name:'PageNotFound', component: PageNotFound },
  { path: "*", redirect: "/404"},
  
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  
});

export default router


let originalTitle = document.title;

function setDocumentTitleHidden() {
  document.title = "Don't Leave Us 😔";
}


function setDocumentTitleVisible() {
  const to = router.currentRoute;
  document.title = `${to.meta.title} | Intrioxa`;
}


document.addEventListener('visibilitychange', () => {
  if (document.hidden) {
    setDocumentTitleHidden();
  } else {
    setDocumentTitleVisible();
  }
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Intrioxa`;
  next();
});

